import { InfoIcon } from '@chakra-ui/icons';
import {
  Tag, TagLabel,
} from '@chakra-ui/react';
import React from 'react';
import { FiHardDrive } from 'react-icons/fi';
import {
  Cpe,
} from '../../models/health';

export interface CpeTagsProps {
  cpe: Cpe
}

export const CpeTags: React.FC<CpeTagsProps> = ({ cpe }) => {
  if (!cpe) {
    return null;
  }

  return (
    <>
      <Tag
        size="lg"
        mb="2"
        colorScheme="green"
        borderRadius="full"
        ml="4"
      >
        <FiHardDrive />
        <TagLabel ml="2">{cpe?.mac || 'unknown'}</TagLabel>
      </Tag>
      <Tag
        size="lg"
        mb="2"
        colorScheme="blue"
        borderRadius="full"
        ml="4"
      >
        <InfoIcon />
        <TagLabel ml="2">{cpe?.vendor || 'unknown'}</TagLabel>
      </Tag>
    </>
  );
};
