import { defineStyleConfig } from '@chakra-ui/react';

const Link = defineStyleConfig({
  baseStyle: (props) => ({
    color: props.color || 'blue.500',
    _hover: {
      filter: 'brightness(0.5)',
      textDecoration: 'underline',
    },
  }),
  variants: {
    white: {
      color: 'white',
      _hover: {
        filter: 'none',
        color: 'deepSpace.100',
        textDecoration: 'none',
      },
      _active: {
        color: 'deepSpace.100',
      },
    },
    light: {
      color: 'deepSpace.100',
      _hover: {
        filter: 'none',
        color: 'white',
        textDecoration: 'none',
      },
      _active: {
        color: 'white',
      },
    },
  },
});

export default Link;
