import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';

export interface SubscriptionCancelModalProps {
  setShowAwayModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  handleCancel: () => void;
}

export const SubscriptionCancelModal: React.FC<SubscriptionCancelModalProps> = ({ handleCancel, showModal, setShowAwayModal }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleConfirm = () => {
    if (isChecked) {
      // Handle the confirmation logic here
      setShowAwayModal(false);
      handleCancel();
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={() => {
        setShowAwayModal(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancel Subscription</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>
            <p>You are about to disconnect your service.</p>

            <p>This process may take a few hours but is usually completed within minutes. Once disconnected, your service will be fully deactivated. Please note the following:</p>

            <b>Data Retention</b>
            : In compliance with data retention obligations, we are required to retain certain information, including your IP address that was assigned to you while you were subscribed to Nepune Internet and your full name, for a period of 2 years.

            We are sorry to see you go. Please confirm below to proceed with the disconnection.
          </Text>
          <Checkbox
            isChecked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          >
            I accept that
            {' '}
            <b>the service will be fully disconnected.</b>
          </Checkbox>
        </ModalBody>

        <ModalFooter>
          <Button backgroundColor="blue.300" color="white" onClick={handleConfirm} isDisabled={!isChecked}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
