import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Link,
  Text,
} from '@chakra-ui/react';

export interface SubscriptionAwayModalProps {
  setShowAwayModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  handleAway: () => void;
}

export const SubscriptionAwayModal: React.FC<SubscriptionAwayModalProps> = ({ handleAway, showModal, setShowAwayModal }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleConfirm = () => {
    if (isChecked) {
      // Handle the confirmation logic here
      setShowAwayModal(false);
      handleAway();
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={() => {
        setShowAwayModal(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Pause Subscription (Away Mode)</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>
            You are setting your subscription to
            {' '}
            <b>away mode.</b>
            {' '}
            This means your subscription speed will be limited to
            {' '}
            <b>10 Mbps</b>
            {' '}
            for both upload and download.
          </Text>
          <Text mb={4}>
            You will be charged
            {' '}
            <b>$1.3 per day</b>
            {' '}
            (including GST) for each day this mode is active, and your regular monthly charge will be paused. When you choose to unpause, you will need to select a new plan.
          </Text>
          <Text mb={4}>
            This mode is ideal for keeping smart devices at home online while you are away.
          </Text>
          <Checkbox
            isChecked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          >
            I accept the daily charge of $1.3
            (including GST) and
            {' '}
            <Link
              href="https://www.neptune.net.au/terms"
              isExternal
              rel="noopener noreferrer"
              target="_blank"
              color="black"
            >
              The Terms of Service.
            </Link>
          </Checkbox>
        </ModalBody>

        <ModalFooter>
          <Button backgroundColor="blue.300" color="white" onClick={handleConfirm} isDisabled={!isChecked}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
