import { defineStyleConfig } from '@chakra-ui/react';

const Card = defineStyleConfig({
  baseStyle: {
    container: {
      borderRadius: 'xl',
    },
  },
  variants: {
    deepSpace: {
      container: {
        bgGradient: 'linear(to-br, deepSpace.700, deepSpace.900)',
        color: 'white',
      },
    },
    blue: {
      container: {
        bgGradient: 'linear(to-br, blue.300, blue.500)',
        color: 'white',
      },
    },
  },
});

export default Card;
