import { defineStyleConfig } from '@chakra-ui/react';

const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: 'full',
    fontFamily: 'Belfast Grotesk',
  },
  sizes: {
    xs: {
      fontWeight: 400,
      fontSize: 'xs',
      px: 8,
    },
    sm: {
      fontWeight: 500,
      fontSize: 'xs',
      px: 4,
    },
    md: {
      fontWeight: 500,
      fontSize: 'md',
      px: 6,
    },
    lg: {
      fontWeight: 500,
      fontSize: 'md',
    },
    xl: {
      fontWeight: 700,
      fontSize: 'lg',
      px: 12,
      py: 5,
    },
  },
  variants: {
    white: {
      backgroundColor: 'white',
      color: 'black',
      _hover: {
        backgroundColor: 'deepSpace.50',
        cursor: 'pointer',
        _disabled: {
          cursor: 'not-allowed',
          background: 'deepSpace.50',
        },
      },
      _active: {
        backgroundColor: 'deepSpace.50',
        cursor: 'pointer',
      },
      _focus: {
        backgroundColor: 'deepSpace.50',
      },
      _disabled: {
        bg: 'deepSpace.50',
      },
    },
    paleGhost: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      color: 'white',
      _hover: {
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        cursor: 'pointer',
      },
      _active: {
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
      },
    },
    ghost: {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      color: 'white',
      _hover: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        cursor: 'pointer',
      },
      _active: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
      },
    },
    cyan: {
      bg: 'cyan.500',
      color: 'black',
      _hover: {
        bg: 'cyan.600',
        cursor: 'pointer',
        _disabled: {
          cursor: 'not-allowed',
          background: 'cyan.600',
        },
      },
      _active: {
        bg: 'cyan.600',
      },
      _disabled: {
        bg: 'cyan.600',
      },
    },
    deepSpace: {
      bg: 'deepSpace.900',
      color: 'white',
      _hover: {
        bg: 'deepSpace.700',
        cursor: 'pointer',
        _disabled: {
          cursor: 'not-allowed',
          background: 'deepSpace.700',
        },
      },
      _active: {
        bg: 'deepSpace.800',
      },
      _disabled: {
        bg: 'deepSpace.700',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'cyan',
  },
});

export default Button;
