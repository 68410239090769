import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import customerReducer from '../features/customer/customerObjectSlice';
import authReducer from '../features/auth/auth';
import { customerApi } from '../services/eventsService';

export const store = configureStore({
  reducer: {
    customer: customerReducer.reducer,
    auth: authReducer.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
  },

  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(customerApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
