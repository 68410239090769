import { LiveChatLoaderProvider, Intercom } from 'react-live-chat-loader';
import React from 'react';
import { useGetCustomerQuery } from '../services/eventsService';
import { useAppSelector } from '../app/hooks';

interface Props {
    appId: string;
}

// This component just forces Intercom widget to be lazily loaded only when the
// user wants it client side so that it doesn't slow down Remix routes.
// See more: https://github.com/calibreapp/react-live-chat-loader
const IntercomWidget = ({ appId }: Props) => {
  const token = useAppSelector((state) => state.auth.token);
  const {
    isLoading: isCustomerLoading,
  } = useGetCustomerQuery('', {
    skip: !token,
  });

  return isCustomerLoading ? null : (

    <LiveChatLoaderProvider
      providerKey={appId}
      provider="intercom"
    >
      <Intercom />
    </LiveChatLoaderProvider>
  );
};

export default IntercomWidget;
