import React from 'react';
import { Button } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/dashboard',
      },
    });
  };

  return (
    <Button
      colorScheme="teal"
      color="black"
      borderRadius="20px"
      data-testid="login-button"
      onClick={handleLogin}
    >
      Log In
    </Button>
  );
};

export default LoginButton;
