import { defineStyleConfig } from '@chakra-ui/react';

export const Heading = defineStyleConfig({
  variants: {
    mini: {
      textTransform: 'uppercase',
      fontWeight: 500,
      letterSpacing: 1.25,
      mb: '1rem',
      fontSize: '0.65rem',
    },
  },
});

export const Text = defineStyleConfig({
  variants: {
    mini: {
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: '0.65rem',
    },
  },
});
