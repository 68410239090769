import {
  Alert, AlertDescription, AlertIcon, AlertTitle, Stack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useAppSelector } from '../app/hooks';

import { Subscription } from '../models/customer';
import { ServiceCard } from './ServiceCards';
import { Loading } from './Loading';
import { DashboardTemplate } from './page_templates';
import NotWithNeptuneYet from './NotWithNeptuneYet';
import { useGetCustomerQuery } from '../services/eventsService';

const Dashboard = () => {
  const token = useAppSelector((state) => state.auth.token);
  const {
    data: customerData, error: customerError, isLoading: isCustomerLoading, refetch,
  } = useGetCustomerQuery('', {
    pollingInterval: 15000,
    skip: !token,
  });

  const [focused, setFocused] = useState('');

  if (customerError) {
    return (
      <>
        <Alert
          textAlign="center"
          colorScheme="pink"
          w="100%"
          p={4}
          color="black"
        >
          <AlertIcon />
          <AlertTitle textAlign="center">
            <a href="https://neptune.net.au/">
              Error occurred
            </a>
          </AlertTitle>
          <AlertDescription>{'data' in customerError ? customerError.data as string : 'unknown'}</AlertDescription>
        </Alert>
        {!customerData?.subscriptions && <NotWithNeptuneYet /> }
      </>
    );
  }

  if (isCustomerLoading) {
    return <Loading />;
  }

  return (
    <DashboardTemplate>
      <Stack direction={['column', 'column', 'column', 'row']} alignItems="ceneter" justifyContent="center">
        {customerData?.subscriptions !== undefined
          && Object.keys(customerData?.subscriptions).map((key: string) => {
            const sub = customerData?.subscriptions[key] || ({} as Subscription);
            return (
              <Stack
                p={3}
                py={3}
              >
                <ServiceCard
                  email={customerData?.customer?.email}
                  serviceId={sub.id}
                  subTitle={`NBN Location: ${sub.loc_id}`}
                  sub={sub}
                  title={sub.nbn_details.addressDetail.formattedAddress}
                  currentDiscount={0.7}
                  monthlyCost={sub.plan.cost}
                  upSpeed={sub.plan.up_mbps}
                  downSpeed={sub.plan.down_mbps}
                  onPick={() => null}
                  focused={focused}
                  setFocused={(foo) => setFocused(foo)}
                />
              </Stack>
            );
          })}
      </Stack>
    </DashboardTemplate>
  );
};

export default Dashboard;
