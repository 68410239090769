import { extendTheme } from '@chakra-ui/react';
import Button from './componentStyle/Button';
import Card from './componentStyle/Card';
import Input from './componentStyle/Input';
import Link from './componentStyle/Link';
import { Heading, Text } from './componentStyle/Typography';

const colors = {
  black: '#000F0F',
  cyan: {
    50: '#E5FFFF',
    100: '#B8FFFF',
    200: '#8AFFFF',
    300: '#5CFFFF',
    400: '#2EFFFF',
    500: '#00FFFF',
    600: '#00CCCC',
    700: '#009999',
    800: '#006666',
    900: '#003333',
  },
  blue: {
    50: '#E5E5FF',
    100: '#B8B8FF',
    200: '#8A8AFF',
    300: '#5C5CFF',
    400: '#2E2EFF',
    500: '#0000FF',
    600: '#0000CC',
    700: '#000099',
    800: '#000066',
    900: '#000033',
  },
  deepSpace: {
    900: '#001A1A',
    800: '#193030',
    700: '#334848',
    600: '#4C5E5E',
    500: '#667676',
    400: '#7F8C8C',
    300: '#99A3A3',
    200: '#B2BABA',
    100: '#CCD1D1',
    50: '#E5E8E8',
  },
};

const fonts = {
  heading: "'Belfast Grotesk', sans-serif",
  body: "'Montserrat', sans-serif",
};

const fontSizes = {
  xs: '0.8rem',
  '5xl': '3.2rem',
};

const components = {
  Button,
  Card,
  Input,
  Heading,
  Link,
  Text,
};

const theme = extendTheme({
  colors,
  fonts,
  fontSizes,
  components,
});

export default theme;
