import {
  Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, CloseButton, useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

export interface HealthStatusAlertProps {
  status: 'error' | 'success' | 'warning' | 'info'
  title: string
  description: string
}

export const HealthStatusAlert: React.FC<HealthStatusAlertProps> = ({ status, title, description }) => {
  const {
    isOpen: isVisible,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: true });
  if (!title) {
    return null;
  }

  return (isVisible
    ? (
      <Alert
        color="black"
        status={status}
      >
        <AlertIcon />
        <Box>
          <AlertTitle>{title}</AlertTitle>
          <AlertDescription>{description}</AlertDescription>

        </Box>
        <CloseButton
          position="absolute"
          right="8px"
          top="8px"
          onClick={onClose}
        />

      </Alert>
    ) : (
      <Box mt="5" justifyContent="center">
        <Button onClick={onOpen}>
          Event
          {' '}
          {title}
        </Button>
      </Box>
    ));
};
