import { Buffer } from 'buffer';
import {
  Box,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  SlideFade,
  Text,
  Icon,
  Card,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useMediaQuery,
  Tooltip,
  createStandaloneToast,
  useClipboard,
  Code,
  Collapse,
} from '@chakra-ui/react';
import React, { useState, useCallback, useEffect } from 'react';
import {
  FiActivity,
  FiCheckCircle,
  FiCopy,
  FiGlobe,
  FiHelpCircle,
  FiShield,
  FiTrash,
} from 'react-icons/fi';
import {
  HamburgerIcon,
} from '@chakra-ui/icons';

import diamondClayUrl from '../../images/diamond-clay.png';
import { VPN } from '../../models/vpn';
import { Help } from '../Help';
import { getConfig, IBackendConfig } from '../../config';
import { useDeleteVPNMutation, useUpdateVPNMutation } from '../../services/eventsService';
import { QRCodeGenerator } from '../QrCodeGenerator';

const cfg: IBackendConfig = getConfig();

export interface VPNServiceCardProps {
  sub: VPN;
  serviceId: string;
  title: string;
  subTitle: string;
  monthlyCost: number;
  currentDiscount: number;
  downSpeed: number;
  upSpeed: number;
  onPick: () => void;
  qr_code?: string;
  config?: string;
  focused: string;
  email: string;
  setFocused: (_: string) => void;
}

const IPv6Help = 'Neptune Internet allocates 64 bit IPv6 prefixes per service. An IPv6 prefix is like the first part of an IP address that helps identify a network. Think of it like a postal code or neighborhood name in a street address—it tells you the general area (network) where a device is located. In IPv6, addresses are much longer, and the prefix usually indicates the network or subnet where the device belongs. For example, if the address is 2001:0db8:85a3::8a2e:0370:7334, the prefix might be 2001:0db8::/32. This tells the network which part of the address is used for identifying the network and which part is for identifying the specific device.';

const IPv4Help = "An IP address is a unique identifier that allows devices to communicate on a network, like an address for sending data. IPv4 is the older system, using a 32-bit format that can handle about 4.3 billion addresses, but it's running out due to the growing number of internet-connected devices. IPv6 is the newer version, offering a much larger address space with 128-bit addresses, designed to support the internet's future growth and provide better security. Both are used today, but IPv6 is slowly replacing IPv4.";

const AtmosphereHelp = 'Atmosphere is a Sinkhole DNS Protection Service that is fully managed by Neptune Internet for you. Sinkhole DNS protection is like setting up a trap to catch harmful internet traffic before it reaches your device. Normally, when you type a website address, your device uses DNS (Domain Name System) to find the website\'s location. But sometimes, cybercriminals use fake websites (like phishing or malware sites) to steal information or infect your device.With sinkhole DNS protection, if your device tries to access a dangerous or fake website, the DNS server is set up to "sinkhole" the request. Instead of sending you to the bad site, it redirects you to a safe place, like a warning page or just blocks the connection. It’s like having a safety net that stops you from accidentally visiting harmful sites.';

const HealthCheckHelp = 'A health check will perform a diagnostic procedure that runs multiple tests on the NBN infrastructure and Neptune Internet equipment. We will also try to detect the status of your CPE (Customer Premises Equipment) and your current IP address.';

const toast = createStandaloneToast();

const decodeBase64 = (base64String: string) => {
  try {
    const foo = Buffer.from(base64String, 'base64').toString('utf-8');
    return foo;
  } catch (error) {
    console.error('Invalid base64 string', error);
    return null;
  }
};

export const VPNServiceCard = ({
  serviceId,
  sub,
  email,
  title,
  subTitle,
  monthlyCost,
  downSpeed,
  upSpeed,
  qr_code = '',
  config = '',
  onPick,
  focused,
  setFocused,
  currentDiscount,
}: VPNServiceCardProps) => {
  const [showIPHelp, setShowIPHelp] = useState(false);
  const [showConf, setShowConf] = useState(false);
  const [showIPv6Help, setShowIPv6Help] = useState(false);
  const [showAtmosphereHelp, setShowAtmosphereHelp] = useState(false);
  const [showHealthCheckHelp, setShowHealthCheckHelp] = useState(false);

  const isFocused = useCallback((id: string) => focused === id, [focused]);
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  const [deleteVPN, { isLoading: deleteVpnIsLoading, isSuccess: deleteVpnIsSuccess, error: deleteVpnIsError }] = useDeleteVPNMutation();
  const [updateVPN, { isLoading: updateVpnIsLoading, isSuccess: updateVpnIsSuccess, error: updateVpnIsError }] = useUpdateVPNMutation();

  const handleSubmit = onPick;
  const toggleCodeCollapse = () => setShowConf(!showConf);

  const getImage = diamondClayUrl;

  const showToaster = (toasterTitle: string, status: 'success' | 'error' | 'info' | 'warning') => {
    toast.toast({
      title: toasterTitle,
      status,
      duration: 5000,
      position: 'top',
    });
  };

  const {
    onCopy, value, setValue, hasCopied,
  } = useClipboard('');

  useEffect(() => {
    setValue(decodeBase64(sub?.config) || '');
  }, [sub?.config]);

  return (
    <Flex direction={['column', 'column', 'column', 'row']} gap={6}>
      <Box
        sx={{
          _hover: {
            cursor: 'pointer',
          },
        }}
        width="33%%"
        flexBasis={isFocused(serviceId) ? '200%' : '100%'}
        transition="flex-basis 0.2s ease"
        onMouseEnter={() => setFocused(serviceId)}
        onMouseLeave={() => setFocused('')}
        display="flex"
        flexDirection="column"
        key={serviceId}
      >
        {showIPHelp && (
          <Help
            helpHeader="What is IP address ?"
            helpText={IPv4Help}
            isOpen={showIPHelp}
            onClose={() => setShowIPHelp(false)}
          />
        )}
        {showIPv6Help && (
          <Help
            helpHeader="What is IPv6 prefix?"
            helpText={IPv6Help}
            isOpen={showIPv6Help}
            onClose={() => setShowIPv6Help(false)}
          />
        )}
        {showAtmosphereHelp && (
          <Help
            helpHeader="What is Atmosphere?"
            helpText={AtmosphereHelp}
            isOpen={showAtmosphereHelp}
            onClose={() => setShowAtmosphereHelp(false)}
          />
        )}
        {showHealthCheckHelp && (
          <Help
            helpHeader="What are Health Checks?"
            helpText={HealthCheckHelp}
            isOpen={showHealthCheckHelp}
            onClose={() => setShowHealthCheckHelp(false)}
          />
        )}

        <Card
          minHeight="350px"
          flex="1"
          variant={isFocused(serviceId) ? 'blue' : 'deepSpace'}
        >
          <Box
            display="flex"
            flexDirection="column"
            flex="1"
            bgPosition={
              isFocused(serviceId)
                ? 'bottom -100px right -100px'
                : 'top -150px right -150px'
            }
            bgSize="500px"
            bgRepeat="no-repeat"
            bgImage={diamondClayUrl}
          >
            <CardHeader>
              <Heading
                fontSize={isFocused(serviceId) ? '20px' : '16px'}
                fontWeight="bold"
                transition="font-size 0.2s ease-in-out"
                mb="3"
              >
                {title}
              </Heading>

              <SlideFade
                transition={{
                  enter: { duration: 0.3 },
                  exit: { duration: 0.3 },
                }}
                in={isFocused(serviceId)}
              >
                <Text noOfLines={2}>
                  <Menu>
                    {isLargerThan768 ? (
                      <MenuButton
                        marginRight={4}
                        borderRadius={50}
                        bgColor="deepSpace.700"
                        width={100}
                      >
                        Actions
                      </MenuButton>
                    ) : (
                      <MenuButton
                        icon={<HamburgerIcon />}
                        aria-label="Open Menu"
                        display={{ md: 'none' }}
                        marginRight={4}
                        as={IconButton}
                      />
                    )}
                    <MenuList>
                      <Tooltip placement="bottom" label={AtmosphereHelp} closeDelay={0} openDelay={2000}>
                        <MenuItem
                          icon={<FiShield />}
                          command="⌘A"
                          color="black"
                          disabled
                        >
                          Atmosphere
                        </MenuItem>
                      </Tooltip>

                      <Tooltip placement="bottom" label={HealthCheckHelp} closeDelay={0} openDelay={2000}>
                        <MenuItem
                          color="black"
                          icon={<FiActivity />}
                          command="⌘H"
                          disabled
                        >
                          Health Check
                          {' '}
                        </MenuItem>
                      </Tooltip>
                      <MenuItem
                        color="pink.500"
                        icon={<FiTrash />}
                        command="⌘D"
                        onClick={async () => {
                          deleteVPN(sub.id).unwrap()
                            .then(() => showToaster('VPN tunnel has been successfully deleted.', 'success')).catch((err) => showToaster('An error occured deleting the tunnel.', 'error'));
                        }}
                      >
                        {deleteVpnIsLoading ? 'Deleting...' : 'Delete VPN'}
                      </MenuItem>
                    </MenuList>
                  </Menu>

                  {subTitle}
                </Text>
              </SlideFade>
            </CardHeader>
            <CardBody display="flex" flexDirection="column">
              <Box marginBottom={4}>
                <Heading size="xs">
                  <Icon as={FiGlobe} />
                  {' '}
                  IPv4 Address:
                  {' '}
                  {sub.ip}
                  <IconButton
                    marginLeft="2"
                    size="xxs"
                    aria-label="ip address help"
                    icon={<FiHelpCircle />}
                    onClick={() => setShowIPHelp(true)}
                  />
                </Heading>
              </Box>

              <Box marginBottom={4}>
                <Heading size="xs">
                  <Icon as={FiGlobe} />
                  {' '}
                  IPv6 Prefix:
                  {' '}
                  {sub.ip_6}
                  <IconButton
                    marginLeft="2"
                    size="xxs"
                    aria-label="ipv6 prefix address help"
                    icon={<FiHelpCircle />}
                    onClick={() => setShowIPv6Help(true)}
                  />
                </Heading>
              </Box>
              <Box>
                <Heading size="small">
                  <Text color="cyan">
                    <Icon as={FiCheckCircle} />
                    {' '}
                    Service Status: Healthy
                  </Text>
                </Heading>
              </Box>
            </CardBody>
            <CardBody display="flex" alignItems="flex-end">
              <Box>
                <Heading fontWeight="500" as="p" size="lg">
                  $
                  {Math.ceil(monthlyCost * currentDiscount)}
                </Heading>
                <Text color="deepSpace.100" variant="mini">
                  Per month
                </Text>
                <SlideFade
                  transition={{
                    enter: { duration: 0.3 },
                    exit: { duration: 0.3 },
                  }}
                  in={isFocused(serviceId)}
                >
                  <Text
                    color="deepSpace.100"
                    variant="mini"
                    mb="1rem"
                    noOfLines={1}
                  >
                    {currentDiscount !== 1
                      ?? `Including promotional discount of ${currentDiscount}`}
                  </Text>
                </SlideFade>
              </Box>
            </CardBody>
            <CardFooter>
              {qr_code === '' ? (
                <Button
                  type="button"
                  backgroundColor="pink"
                  onClick={() => {
                    updateVPN({ vpn_id: sub.id, private_key: '' })
                      .unwrap()
                      .then(() => showToaster('The keys are reset please update your config or click show QR code.', 'warning'))
                      .catch((err) => showToaster('Error resetting the keys', 'error'));
                  }}
                  isLoading={updateVpnIsLoading}
                  disabled={updateVpnIsLoading}
                >
                  Reset Keys
                </Button>
              )
                : (

                  <Box display="flex" flexDir="column" gap="4" width="300px">
                    <QRCodeGenerator url={decodeBase64(sub.config)!} />
                    <Button size="xs" borderRadius="25" aria-label="wireguard qr code" leftIcon={<FiCopy size="16" />} onClick={onCopy}>{hasCopied ? (<b>Copied!</b>) : 'Copy Wireguard Config'}</Button>
                    <Button size="xs" borderRadius="25" aria-label="show config" onClick={toggleCodeCollapse}>{showConf ? 'Hide config' : 'Show Wireguard config'}</Button>
                    <Collapse title="Wireguard config" in={showConf} animateOpacity>
                      <Code
                        as="pre" // Preformatted block
                        p={4}
                        whiteSpace="pre-wrap" // Preserve line breaks and spaces
                        bg="gray.100"
                        borderRadius="md"
                        overflowX="auto" // Horizontal scrolling
                        maxWidth="300px"
                      >
                        {decodeBase64(sub.config)}
                      </Code>
                    </Collapse>
                  </Box>
                ) }
            </CardFooter>
          </Box>
        </Card>
      </Box>
    </Flex>
  );
};
