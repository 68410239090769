import React, { useRef, useState } from 'react';
import {
  Button, Box,
} from '@chakra-ui/react';
import { QRCodeSVG } from 'qrcode.react';
import { FiCode } from 'react-icons/fi';
import { ViewOffIcon } from '@chakra-ui/icons';

export interface QRCodeGeneratorProps {
  url: string
  size?: 'lg' | 'md' | 'sm' | 'xs'
}
export const QRCodeGenerator: React.FC<QRCodeGeneratorProps> = ({ url, size = 'xs' }) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [showQrCode, setShowQrCode] = useState(false);

  const scrollToElement = () => {
    svgRef.current?.scrollIntoView({
      behavior: 'smooth', // Smooth scrolling
      block: 'center', // Aligns the element to the center of the viewport
    });
  };

  const handleGenerate = () => {
    setShowQrCode(!showQrCode);
  };

  return (
    <Box display="flex" flexDir="column">
      <Button
        size={size}
        aria-label="generate qr code"
        leftIcon={showQrCode ? <ViewOffIcon /> : <FiCode />}
        colorScheme="blue"
        onClick={async () => {
          await handleGenerate();
          scrollToElement();
        }}
      >
        {showQrCode ? 'Hide QR' : 'Show QR'}

      </Button>
      {showQrCode && (
        <Box mt="6" p="4" border="1px solid #e2e8f0" borderRadius="md" flexDir="column">
          <QRCodeSVG tabIndex={0} ref={svgRef} value={url} size={256} />
        </Box>
      )}
    </Box>
  );
};
