/* eslint-disable-next-line */
export enum EventType {
    Unknown,
    AuthzSuccessful,
    ServiceGreen,
    ServiceAmber,
    ServiceRed,
    ServiceGrey,
    HealthCheckPending,
    HealthCheckComplete,
    SubscriptionCreated,
    SubscriptionCancelled,
    SubscriptionUpdated,
    AtmosphereEnabled,
    AtmosphereDisabled,
    AtmosphereUpdated,
    NBNActivated,
    NBNMaintenanceNotification,
    ReferralSignup,
    ReferralCreditApplied,
}

export interface Event {
  timestamp: string,
  event_type: EventType,
  payload: string,
}

export function eventTypeToString(e: EventType): [string, 'error' | 'warning' | 'success' | 'info', string] {
  switch (e) {
    case EventType.HealthCheckComplete:
      return ['The health check has been completed successfully. Please check the result on the service health page.', 'info', ''];
    case EventType.HealthCheckPending:
      return ['Health check is pending results, please wait.', 'info', ''];
    case EventType.AuthzSuccessful:
      return ['Successfully authorized subscription.', 'success', ''];

    case EventType.ServiceGreen:
      return ['Service is healthy.', 'success', ''];
    case EventType.ServiceAmber:
      return ['Service might need your attention.', 'warning', ''];
    case EventType.ServiceRed:
      return ['Service is not healthy.', 'error', ''];

    case EventType.ServiceGrey:
      return ['Service status is unknown.', 'info', ''];
    case EventType.SubscriptionCreated:
      return ['Subscription has been created successfully.', 'info', ''];
    case EventType.SubscriptionUpdated:
      return ['Subscription has been updated successfully.', 'info', ''];
    case EventType.AtmosphereEnabled:
      return ['Atmosphere has been enabled.', 'success', ''];
    case EventType.AtmosphereDisabled:
      return ['Atmosphere has been disabled.', 'error', ''];
    case EventType.AtmosphereUpdated:
      return ['Atmosphere settings have been updated.', 'info', ''];
    case EventType.NBNActivated:
      return ['NBN connection has been successfully activated.', 'success', ''];
    case EventType.NBNMaintenanceNotification:
      return ['NBN has a planned maintenance that may affect your service.', 'warning', ''];
    default:
      return ['Unknown', 'info', ''];
  }
}
