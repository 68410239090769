import React from 'react';
import {
  Box, Center, Grid, GridItem, Spinner,
} from '@chakra-ui/react';
import twoClayHemispheres from '../images/two-clay-hemispheres.png';
import NavBar from './NavBar';
import { Footer } from './Footer';

export const Loading = () => (
  <Box bgColor="deepSpace.900">
    <NavBar email="unknown" avatarLink="#" />
    <Box
      px={['0.5rem', '1rem', '1.5rem', '2rem']}
      style={{
        backgroundImage: `url("${twoClayHemispheres}"), linear-gradient(to right bottom, #001A1A, #000F0F)`,
      }}
      sx={{
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
      color="white"
    >
      <Grid
        templateAreas={`"header"
                      "main"
                      "footer"`}
        margin="5px"
        h="100%"
        minHeight="100vh"
        flexGrow="row nowrap"
        gap="1"
      >
        <GridItem>
          <Center>
            <Spinner size="xl" />
          </Center>
        </GridItem>
        <GridItem bgColor="deepSpace.900" area="footer">
          <Footer />
        </GridItem>
      </Grid>
    </Box>
  </Box>
);
