import React from 'react';
import { Text } from '@chakra-ui/react';
import { Subscription } from '../models/customer';
import { getRemainingMonths } from '../utils';
import { Plan } from '../models/plans';

interface DiscountWarningProps {
  plan: Plan
  sub: Subscription
  isFocused: (arg: string) => boolean
  couponIds?: Array<string>
}

const DiscountWarning: React.FC<DiscountWarningProps> = ({
  plan, sub, isFocused, couponIds = ['WELCOME', 'WELCOME20'],
}) => {
  const remainingMonths = getRemainingMonths(sub?.created_at || 0);

  const hasValidCoupon = sub?.billing_details?.coupons
    && remainingMonths > 0
    && sub.billing_details.coupons.some((coupon) => couponIds.includes(coupon.id));

  if (!hasValidCoupon) return null;

  if (remainingMonths === 0) {
    return null;
  }

  return (
    <Text mt={2} color="deepSpace.900" variant="mini" mb="2rem">
      <b>
        Warning: you have
        {' '}
        {remainingMonths}
        {' '}
        promotional months remaining on your current plan.
      </b>
      <Text color="deepSpace.900" variant="mini" mb="1rem" noOfLines={2}>
        Switching to a new plan will remove your discount, and it will not apply to the new plan.
      </Text>
    </Text>
  );
};

export default DiscountWarning;
