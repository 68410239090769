import {
  Box, Center, Text,
} from '@chakra-ui/react';
import React from 'react';
import LoginButton from './LoginButton';
import { DashboardTemplate } from './page_templates';

interface NotAuthenticatedProps {
  emailVerify?: boolean
}

const NotAuthenticated: React.FC<NotAuthenticatedProps> = ({ emailVerify = false }) => (
  <DashboardTemplate>
    <Box display="flex" flexDir="column" position="relative" h="100px">
      <Center style={{ paddingTop: '2%' }}>
        {!emailVerify ? <LoginButton /> : null}
      </Center>
      <Center style={{ paddingTop: '2%' }} mb="10">
        <Text fontSize="xl" color="white">
          Neptune subscribers portal
        </Text>
      </Center>
    </Box>
  </DashboardTemplate>
);

export default NotAuthenticated;
