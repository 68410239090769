import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface AuthState {
  ip: string;
  token: string;
  verified: boolean;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: AuthState = {
  ip: 'unknown',
  token: '',
  verified: false,
  status: 'idle',
};
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state = { ...initialState };
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    setVerified(state, action) {
      state.verified = action.payload;
    },
    setIP(state, action) {
      state.ip = action.payload;
    },
  },
});

export const selectCustomerObject = (state: RootState) => state.customer;
const { actions, reducer } = authSlice;
export const { setIP, setToken, setVerified } = actions;
export default authSlice;
