import { defineStyleConfig } from '@chakra-ui/react';

const Input = defineStyleConfig({
  baseStyle: {},
  sizes: {
    lg: {
      field: {
        fontSize: 'sm',
      },
    },
  },
  variants: {
    ghost: {
      field: {
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        color: 'white',
        _placeholder: {
          color: 'white',
        },
        _focus: {
          border: '1px solid rgba(255, 255, 255, 0.3)',
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
        },
      },
    },
  },
});

export default Input;
