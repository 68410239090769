import { useAuth0 } from '@auth0/auth0-react';
import { InfoIcon, WarningIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  createStandaloneToast,
  FormControl,
  FormLabel,
  Heading,
  List,
  ListItem,
  SimpleGrid,
  Spinner,
  Stack,
  Switch,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';
import {
  fetchCustomerObject,
  fetchGroupsAsync,
  permitAsyncRegex,
  removePermitAsyncRegex,
  setGroupsAsync,
} from '../features/customer/customerObjectSlice';
import { DashboardTemplate } from './page_templates';

const toast = createStandaloneToast();

const showToaster = (toasterTitle: string, status: 'success' | 'error' | 'info' | 'warning') => {
  toast.toast({
    title: toasterTitle,
    status,
    duration: 5000,
    position: 'top',
  });
};

export const Atmosphere = () => {
  const dispatch = useAppDispatch();
  const { subId } = useParams();

  const [token, setToken] = useState('');
  const [groupsToSet, setGroupsToSet] = useState<Array<number> | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  useEffect(() => {
    if (token !== '') {
      dispatch(fetchCustomerObject(token));
      dispatch(fetchGroupsAsync(token));
    }
  }, [token]);

  useEffect(() => {
    async function getClaims() {
      if (isAuthenticated) {
        const idToken = await getAccessTokenSilently();
        setToken(idToken!);
      }
    }
    getClaims();
  }, [isAuthenticated, isLoading]);

  const customerState = useAppSelector((state: RootState) => state.customer);

  useEffect(() => {
    setLoading(true);
    try {
      if (
        customerState.value.subscriptions
        && customerState.value.subscriptions[subId || '']
      ) {
        setGroupsToSet(
          customerState.value.subscriptions[subId || ''].dns_neptune_groups,
        );
      }
    } catch (err) {
      console.log('error', err);
    } finally {
      setLoading(false);
    }
  }, [customerState.value.subscriptions, subId]);

  const handleChange = (e: any) => {
    setLoading(true);
    try {
      if (e && e?.name === 'goog') {
        if (e?.checked) {
          dispatch(
            permitAsyncRegex({
              token,
              subId: subId || '',
              payload: {
                /* eslint-disable */
                allow_list_regex_domains: [
                  "(.|^)googleadservices.com$",
                  "(.|^)ad.doubleclick.net$",
                  "(.|^)dartsearch.net$",
                ],
              },
            }),
          );


        // set the groups too just in case
        dispatch(
          setGroupsAsync({
            token,
            subId: subId || "",

            payload: {
              groups: groupsToSet || [],
            },
          }))
        } else {
          dispatch(
            removePermitAsyncRegex({
              token,
              subId: subId || "",
              payload: {
                /* eslint-disable */
                allow_list_regex_domains: [
                  "(.|^)googleadservices.com$",
                  "(.|^)ad.doubleclick.net$",
                  "(.|^)dartsearch.net$",
                ],
              },
            }),
          );
        }

        return;
      }

      if (e && e?.checked) {
        setGroupsToSet([...(groupsToSet ? groupsToSet : []), +e.value].filter((item, index) => [...(groupsToSet || []), +e.value].indexOf(item) === index));
      }
      if (e && !e.checked) {
        setGroupsToSet([...(groupsToSet || [])?.filter((key) => +key !== +e.value)]);
      }
    } catch (err) {
      showToaster('Failed to update Atmosphere. Please try again later.', 'error');
      console.log("error occurred", err)
    } finally {
      setLoading(false)
    }
  };


  return (
    <DashboardTemplate>
      <Box justifyContent={"center"} textAlign="center"
        gap="10" display={"flex"} flexDir="row" flexWrap={"wrap"}>
        <Stack
          py={4}
          px={4}
          flex={"flex"}
          flexDir={["column", "column", "column", "row"]}
          direction={{
            base: "column",
            md: "row",
          }}
          alignItems={{ md: "center" }}
        >
          {!!customerState?.value?.subscriptions && (
            <Heading size="sm" marginRight="40px">
              {
                customerState?.value?.subscriptions[subId || ""].nbn_details
                  ?.addressDetail?.formattedAddress
              }{" "}
            </Heading>
          )}

          {!groupsToSet || loading ? <Spinner size={"md"} /> : <List spacing={3} textAlign="center">
            <ListItem>
              <FormControl
                columns={{base: 2, lg: 2}}
                as={SimpleGrid}
                onChange={(e) => {
                  e.preventDefault();
                  handleChange(e.target);
                }}
              >
                <FormLabel mb="0">
                  <InfoIcon /> Recommended setting that will block popular
                  advertising networks.
                  <br />
                  <br />
                </FormLabel>
                <Switch
                  size="lg"
                  colorScheme="teal"
                  name="Recommended"
                  value={0}
                  defaultChecked={groupsToSet.includes(0)}
                />
              </FormControl>
            </ListItem>

            <ListItem>
              <FormControl
                columns={{base: 2, lg: 2}}
                as={SimpleGrid}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLoading(true)
                  try {
                    e.preventDefault();
                    handleChange(e.target);
                    showToaster(e.target?.checked ? 'Successfully allowed Google Shopping.' : 'Google Shopping may not work as intended.', e.target?.checked ? 'success' : 'warning');
                  } catch (err) {
                    showToaster('Failed to update Google shopping lists exclusion. Please try again later.', 'error');
                    console.log("error", err)
                  } finally {
                    setLoading(false)
                  }
                }}
              >
                <FormLabel mb="0">
                  <WarningIcon color="pink" /> Permit shopping lists. If you
                  disable this some websites like Google shopping might stop
                  working, please keep it on if in doubt.
                  <br />
                </FormLabel>
                <Switch
                  size="lg"
                  colorScheme="teal"
                  name="goog"
                  value="goog"
                  defaultChecked={
                    customerState?.value?.subscriptions
                      ? customerState?.value?.subscriptions[
                        subId || ""
                      ]?.dns_allowed_regex_domains
                        ?.toString()
                        ?.includes("google")
                      : false
                  }
                />
              </FormControl>
            </ListItem>
            <ListItem>
              {!customerState?.value?.subscriptions ? (
                <Spinner />
              ) : (
                <FormControl
                  columns={{base: 2, lg: 4}}
                  as={SimpleGrid}
                  onChange={(e) => {
                    e.preventDefault();
                    handleChange(e.target);
                  }}
                >
                  {customerState?.atmosphere?.groups &&
                    Object.keys(customerState?.atmosphere?.groups).map(
                      (key) => (
                        <>
                          <FormLabel key={key} htmlFor={key} mb="0">
                            Block {key}
                          </FormLabel>
                          <Switch
                            colorScheme="teal"
                            name={key}
                            id={key}
                            value={customerState.atmosphere.groups[key]}
                            defaultChecked={customerState?.value?.subscriptions[
                              subId || ""
                            ]?.dns_neptune_groups?.includes(
                              customerState.atmosphere.groups[key],
                            )}
                          />
                        </>
                      ),
                    )}
                </FormControl>
              )}
            </ListItem>
          </List>}
        </Stack>
      </Box>


      <Box flexDir="row" display="flex" flexWrap="wrap" gap={"10"} justifyContent="center" textAlign={"center"} mt="4">

          <Button bgColor="gray" isLoading={customerState.status === 'loading'} onClick={() => navigate('/dashboard')}>
            Back to Dashboard
          </Button>
          <Button
            size="md"
            backgroundColor="teal.500"
            borderRadius="20px"
            width="200px"
            variant="solid"
            isLoading={customerState.status === "loading"}
            onClick={() => {
              try {
                dispatch(
                  setGroupsAsync({
                    token,
                    subId: subId || "",

                    payload: {
                      groups: groupsToSet || [],
                    },
                  }),
                );
                showToaster('Atmosphere configuration has been updated successfully.', 'success');
              } catch (err) {
                showToaster('Failed to update Atmosphere. Please try again later.', 'error');
              }
            }}
          >
            Update
          </Button>
  </Box>
    </DashboardTemplate>
  );
};
