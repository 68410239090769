import React, { useEffect, useState } from 'react';
import './App.css';
import { motion } from 'framer-motion';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import { Route, Routes, useLocation } from 'react-router';
import NotAuthenticated from './components/NotAuthenticated';
import Dashboard from './components/Dashboard';
import { AuthenticationGuard } from './components/AuthGuard';
import { NotFound } from './components/NotFound';
import HealthStatus from './components/HealthStatus';
import { Atmosphere } from './components/Atmosphere';
import { useAppDispatch } from './app/hooks';
import { setToken, setVerified } from './features/auth/auth';
import VPNList from './components/VPN';
import Plans from './components/Plans';

const App = () => {
  const [verified, setEmailVerified] = useState(true);
  const {
    isAuthenticated, getIdTokenClaims, getAccessTokenSilently, isLoading,
  } = useAuth0();

  const dispatch = useAppDispatch();

  useEffect(() => {
    async function getClaims() {
      if (isAuthenticated) {
        const idToken = await getAccessTokenSilently();
        await dispatch(setToken(idToken));

        const claims = await getIdTokenClaims();
        if (claims?.email) {
          const userResp = await fetch(`/api/v1/customer/intercom_id/${claims?.email}`, {
            headers: {
              Authorization: `Bearer ${idToken}`,
              ContentType: 'application/json',
            },
          });
          try {
            const userSettings = await userResp.json();

            window.intercomSettings = {
              app_id: 'ifzpfqp9',
              email: claims.email,
              user_hash: userSettings.user_hash,
              name: claims.name,
            };
          } catch (err) {
            console.log('could not load intercom');
          }
        }
      }
      const claims = await getIdTokenClaims();

      if (claims?.email_verified !== undefined) {
        setEmailVerified(!!claims?.email_verified);
        dispatch(setVerified(!!claims?.email_verified));
      }
    }
    getClaims();
  }, [isAuthenticated, dispatch, getAccessTokenSilently, getIdTokenClaims]);

  if (!verified) {
    return (
      <>
        <Alert status="error" backgroundColor="pink">
          <AlertIcon />
          <AlertTitle color="black">Please verify your email!</AlertTitle>
          <AlertDescription color="black">
            We have sent you an email verification link. Please check your spam
            or junk folder as well.
          </AlertDescription>
        </Alert>
        <NotAuthenticated emailVerify />
      </>
    );
  }

  const location = useLocation();

  return (
    <motion.div
      key={location.pathname}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Routes>
        <Route path="/" element={<AuthenticationGuard component={Dashboard} />} />
        <Route
          path="/dashboard"
          element={<AuthenticationGuard component={Dashboard} />}
        />
        <Route
          path="/vpn"
          element={<AuthenticationGuard component={VPNList} />}
        />
        <Route
          path="/health/:subId"
          element={<AuthenticationGuard component={HealthStatus} />}
        />
        <Route
          path="/subscriptions/:subId/plans"
          element={<AuthenticationGuard component={Plans} />}
        />
        <Route
          path="/atmosphere/:subId"
          element={<AuthenticationGuard component={Atmosphere} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </motion.div>
  );
};

export default App;
