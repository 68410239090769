import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';

export interface SubscriptionPauseModalProps {
  setShowPauseModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;

  setShowAwayModal: Dispatch<SetStateAction<boolean>>;
  handlePause: () => void;
}

export const SubscriptionPauseModal: React.FC<SubscriptionPauseModalProps> = ({
  handlePause, showModal, setShowAwayModal, setShowPauseModal,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleConfirm = () => {
    if (isChecked) {
      // Handle the confirmation logic here
      setShowPauseModal(false);
      handlePause();
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={() => {
        setShowPauseModal(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Pause Subscription</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>
            <p> Pausing your subscription means we will disconnect your NBN service, so no costs are incurred on your behalf during this period.</p>

            <br />
            <b>
              Consider
              {' '}
              <Button
                variant="cyan"
                onClick={() => {
                  setShowPauseModal(false);
                  setShowAwayModal(true);
                }}
              >
                Away mode
              </Button>
              {' '}
              instead, for only $1.3 a day you can keep yourself connected.

            </b>
            <br />
            <br />

            Thank you for being with Neptune, and we look forward to reconnecting when you are ready.
          </Text>
          <Checkbox
            isChecked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          >
            I accept that
            {' '}
            <b>the service will be fully disconnected from the address</b>
            {' '}
            while it is paused.
          </Checkbox>
        </ModalBody>

        <ModalFooter>
          <Button backgroundColor="blue.300" color="white" onClick={handleConfirm} isDisabled={!isChecked}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
